<template>
    <el-form ref="form" :model="form" label-width="140px" class="elForm" :rules="rules">
        <el-form-item label="消费卡名称：" prop="consumer_card_name">
            <el-input placeholder="请输入消费卡名称" type="text" v-model="form.consumer_card_name" style="width: 700px;"></el-input>
        </el-form-item>
        <el-form-item label="消费卡图片：" prop="consumer_card_picture">
            <ReadyUploadSource @getSource="val => (form.consumer_card_picture = val.path)"
                :path="form.consumer_card_picture" @removeThis="() => (form.consumer_card_picture = '')">
            </ReadyUploadSource>
        </el-form-item>
        <el-form-item label="消费卡金额：" prop="consumer_card_amount">
            <el-input type="text" v-model="form.consumer_card_amount" style="width: 700px;">
                <template slot="append">元</template>
            </el-input>
        </el-form-item>
        <el-form-item label="购买金额：" prop="price">
            <el-input type="text" v-model="form.price" style="width: 700px;">
                <template slot="append">元</template>
            </el-input>
        </el-form-item>
        <el-form-item label="展示/下架：" prop="status">
            <el-switch v-model="form.status" :active-value="1" :inactive-value="-1"></el-switch>
        </el-form-item>
        <Preservation @preservation="onSubmit"></Preservation>
    </el-form>
</template>

<script>
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
    components: {
        Preservation,
        ReadyUploadSource
    },
    data () {
        return {
            id: '',
            classify: [],
            form: {
                consumer_card_name: '',
                consumer_card_picture: '',
                consumer_card_amount: '',
                price: '',
                status: 1,
            },
            rules: {
                consumer_card_name: [
                    { required: true, message: '请输入消费卡名称', trigger: 'blur' },
                ],
                consumer_card_picture: [
                    { required: true, message: '请上传消费卡图片', trigger: 'change' },
                ],
                consumer_card_amount: [
                    { required: true, message: '请输入消费卡金额', trigger: 'blur' },
                ],
                price: [
                    { required: true, message: '请输入购买金额', trigger: 'blur' },
                ],
            }
        };
    },
    methods: {
        onSubmit () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let form = { ...this.form }

                    this.$axios.post(this.$api.repair.marketing.ConsumerCardAdd, form).then(res => {
                        if (res.code == 0) {
                            this.$message.success('添加成功');
                            this.$router.go(-1)
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.elForm {
    background-color: #fff;
    padding: 30px 30px 80px;
}

.centerBtn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tips {
    color: #999;
    margin-left: 20px;
}
</style>
